@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Hall of Fun;
        src: url(/fonts/HallOfFun.otf) format("opentype");
    }
    @font-face {
        font-family: Hall of Fun Clean;
        src: url(/fonts/HallOfFun-Clean.otf) format("opentype");
    }
}

@layer components {
    @media (hover: hover) {
        .bg-homepage-hero {
            @apply bg-fixed
        }
    }
}

/* Overrides default "material" style of react-phone-input-2 */
.react-tel-input {
    @apply sm:col-span-2 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary font-sans !important
}

.react-tel-input .form-control {
    @apply block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm pl-16 !important
}

.react-tel-input .special-label {
    @apply absolute -top-2.5 left-2 -mt-px inline-block px-1 bg-white text-sm text-gray-600 ml-2 !important
}

.react-tel-input .country-list .country {
    @apply py-2 !important
}
